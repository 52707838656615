<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateStart"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-date v-model="dateEnd"></v-date>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="indigo" @click="Search">
          <v-icon dark>fa fa-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3">
        <v-btn class="mx-3" fab dark color="green" @click="Prints">
          <v-icon dark>fa fa-print</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-data-table
        :items-per-page="-1"
          :headers="hbillsale"
          :items="dsbillsale.Array"
          class="elevation-1"
        >
          <template v-slot:body.append>
            <tr>
              <td class="text-right">รวม</td>
              <td class="text-right">{{ dsbillsale.Recieve }}</td>
              <td class="text-right">{{ dsbillsale.RecieveSale }}</td>
              <td class="text-right">{{ dsbillsale.Widen }}</td>
              <td class="text-right">{{ dsbillsale.WidenEmp }}</td>
              <td class="text-right">{{ dsbillsale.Wage }}</td>
              <td class="text-right">{{ dsbillsale.Lend }}</td>
              <td class="text-right">{{ dsbillsale.ReTurnMoney }}</td>
              <td class="text-right">{{ dsbillsale.CombackMoney }}</td>
              <td class="text-right">{{ dsbillsale.Total }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dateEnd: "",
      dateStart: "",
      hbillsale: [
        {
          text: "วันที่",
          align: "left",
          sortable: true,
          value: "Date",
        },
        {
          text: "รับเงิน (เพิ่ม)",
          align: "right",
          sortable: true,
          value: "Recieve",
        },
        {
          text: "รับซื่อ",
          align: "right",
          sortable: true,
          value: "RecieveSale",
        },
        {
          text: "เบิกเงินลูกค้า",
          align: "right",
          sortable: true,
          value: "Widen",
        },
        
        {
          text: "เบิกเงินพนักงาน",
          align: "right",
          sortable: true,
          value: "WidenEmp",
        },
        {
          text: "ค่าแรงพนักงาน",
          align: "right",
          sortable: true,
          value: "Wage",
        },
        {
          text: "ยืม",
          align: "right",
          sortable: true,
          value: "Lend",
        },
        {
          text: "คืนเงิน",
          align: "right",
          sortable: true,
          value: "ReTurnMoney",
        },
        {
          text: "รับเงิน (ส่ง)",
          align: "right",
          sortable: true,
          value: "CombackMoney",
        },
        {
          text: "คงเหลือ",
          align: "right",
          sortable: true,
          value: "Total",
        },
      ],
      dsbillsale: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let date = Date.now();
      date = new Date(date);

      let start =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      let end =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.Select(start, end);
    });
  },
  methods: {
    Search() {
      this.Select(this.dateStart, this.dateEnd);
    },
    Select(start, end) {
      axios
        .post("/Report/SelectAbstract", {
          DateStart: start,
          DateEnd: end,
        })
        .then((res) => {
          if (res.status == 200) {
            this.dsbillsale = res.data;
          }
        });
    },
    Prints() {
      if(this.dsbillsale.Array.length <= 0) return;
      axios
        .post("/api/v1/Report/pdf", {
          hdtext : "รายงานรับซื่อ",
          hd: this.hbillsale,
          ite: this.dsbillsale.Array,
          fot: [
            {text:"รวม",cols:2},
            {text:this.dsbillsale.Recieve,cols:1},
            {text:this.dsbillsale.RecieveSale,cols:1},
            {text:this.dsbillsale.Widen,cols:1},
            {text:this.dsbillsale.WidenEmp,cols:1},
            {text:this.dsbillsale.Wage,cols:1},
            {text:this.dsbillsale.Lend,cols:1},
            {text:this.dsbillsale.ReTurnMoney,cols:1},
            {text:this.dsbillsale.CombackMoney,cols:1},
          ]
        })
        .then((res) => {
          if (res.status == 200) {
            this.PrintJs({
              printable: res.data.base64,
              type: "pdf",
              base64: true,
            });
          }
        });
    },
  },
};
</script>
<style></style>
